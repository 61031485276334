<!--
 * @Author: 刘格优
 * @Date: 2020-02-12 14:51:30
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-10 17:22:50
 -->

<template>
  <div class="contentbox">
    <div class="ljyq">
      <p class="word">链接邀请</p>
      <div class="ljbox">
        <div class="boxtop">
          <img src="@/assets/nh/yximg.jpg"
               alt="">
          <div class="right">
            <p class="one">汇立天下邀新（与你一起共享更多的副业薪资）</p>
            <p>加入汇立天下&nbsp;&nbsp;做有温度的保险人</p>
            <p>奖金80%起，汇立天下福利月月有</p>
          </div>
        </div>
        <div class="boxbottom">
          <div @click="shareshow">
            预览页面
          </div>
          <div @click="shareCard">
            立即分享
          </div>
        </div>
      </div>
    </div>
    <div class="hbyq">
      <p class="word">海报邀请</p>
      <img :src="shareimg"
           alt="">
    </div>
    <!-- <div class="hidden">
      <div class="hbbox"
           ref="hbbox">
        <div class="hbimg">
          <img src="@/assets/nh/yxbg.jpg"
               alt="">
          <img :src="ewmimg"
               alt=""
               class="ewm">

        </div>
      </div>
    </div> -->
    <div class="share"
         @click="cancelShare"
         v-show="isShareCard">
      <img src="@/assets/abt/img/fxbg.png" />
    </div>
  </div>

</template>

<script>
import { getYXHB } from '@/api/agent/agent'
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
import { getStorage } from '@/lib/util'
// import { makeImg } from '@/lib/function'
const { redirect_uri } = config
export default {
  data () {
    return {
      isShareCard: false,
      ewmimg: '',
      shareimg: '',
      getossurl: '',
      sharelogo: ''
    }
  },

  components: {},

  computed: {},
  created () {
    let logodata = getStorage('sys_info', {})
    this.sharelogo = logodata.sharelogo ? logodata.sharelogo : ''
    this.getossurl = getStorage('ossurl', '')
    this.share()
  },
  methods: {
    shareCard () {
      this.isShareCard = true
    },
    cancelShare () {
      this.isShareCard = false
    },
    shareshow () {
      this.$router.push({ path: '/invitenew?show=true' })
    },
    share () {
      getYXHB().then((res) => {
        this.shareimg = this.getossurl + res.data.data
        let info = getStorage('u_s', {})
        let shareurl = `${redirect_uri}/Invitenew?tjempno=${info.empno}`

        wechatshare(
          '加入汇立天下，送你300元+50元红包，和我一起签单赚钱吧',
          `一站式保险平台，产品优，推广费高，收入多，保险还可以这样玩`,
          this.sharelogo,
          encodeURI(shareurl),
          redirect_uri,
          'no', '', '', '', '找副业看这里，加入汇立天下，注册领现金，带你玩“赚”保险'
        )
      })

    }
  }
}

</script>
<style scoped lang="stylus">
@import './detail.styl';
</style>
